// App.js - WEB
import React, { Component } from "react";
import { View } from "react-native";
import firebase from 'firebase'
import { connect } from 'react-firebase'

import WebRoutesGenerator from "../../components/src/NativeWebRouteWrapper";
import { ModalContainer } from "react-router-modal";
import HomeScreen from "../../components/src/HomeScreen";
import TopNav from "../../components/src/TopNav";

import InfoPage from '../../blocks/info-page/src/InfoPageBlock'
import AlertBlock from '../../blocks/alert/src/AlertBlock.web'
import ProjectReporting from "../../blocks/ProjectReporting/src/ProjectReporting";
import VisualAnalytics from "../../blocks/visualanalytics/src/VisualAnalytics";
import CustomisableUserProfiles from "../../blocks/CustomisableUserProfiles/src/CustomisableUserProfiles";
import TaskAllocator from "../../blocks/TaskAllocator/src/TaskAllocator.web";
import SocialMediaAccountLoginScreen from "../../blocks/social-media-account-login/src/SocialMediaAccountLoginScreen";
import CreditdebitCardPayments from "../../blocks/CreditdebitCardPayments/src/CreditdebitCardPayments";
import MultipleCurrencySupport from "../../blocks/multiplecurrencysupport/src/MultipleCurrencySupport";
import DataImportexportcsv from "../../blocks/DataImportexportcsv/src/DataImportexportcsv";
import MobileAccountLoginBlock from "../../blocks/mobile-account-login/src/MobileAccountLoginBlock";
import TaxCalculator from "../../blocks/TaxCalculator/src/TaxCalculator";
import ProjectTemplates from "../../blocks/ProjectTemplates/src/ProjectTemplates";
import AdvancedSearch from "../../blocks/advancedsearch/src/AdvancedSearch";
import OTPInputAuth from "../../blocks/otp-input-confirmation/src/OTPInputAuth";
import RolesPermissions2 from "../../blocks/RolesPermissions2/src/RolesPermissions2";
import InvoiceBilling from "../../blocks/InvoiceBilling/src/InvoiceBilling";
import RequestManagement from "../../blocks/RequestManagement/src/RequestManagement";
import PeopleManagement2 from "../../blocks/PeopleManagement2/src/PeopleManagement2";
import Notes from "../../blocks/Notes/src/Notes";
import PaymentAdmin2 from "../../blocks/PaymentAdmin2/src/PaymentAdmin2";
import Customform from "../../blocks/customform/src/Customform";
import JobListing2 from "../../blocks/JobListing2/src/JobListing2";
import Pushnotifications from "../../blocks/pushnotifications/src/Pushnotifications";
import ForgotPassword from "../../blocks/forgot-password/src/ForgotPassword";
import ForgotPasswordOTP from "../../blocks/forgot-password/src/ForgotPasswordOTP";
import NewPassword from "../../blocks/forgot-password/src/NewPassword";
import {NotificationStyles} from "../../blocks/notifications/src/Notifications.web";
import Analytics from "../../blocks/analytics/src/Analytics";
import Customisableusersubscriptions from "../../blocks/customisableusersubscriptions/src/Customisableusersubscriptions";
import SubscriptionDetails from "../../blocks/customisableusersubscriptions/src/SubscriptionDetails";
import AmazonPayIntegration from "../../blocks/AmazonPayIntegration/src/AmazonPayIntegration";
import BaselineReporting from "../../blocks/BaselineReporting/src/BaselineReporting";
import AdminConsole3 from "../../blocks/AdminConsole3/src/AdminConsole3";
import Appointments from "../../blocks/appointmentmanagement/src/Appointments";
import AddAppointment from "../../blocks/appointmentmanagement/src/AddAppointment";
import Scheduling from "../../blocks/scheduling/src/Scheduling";
import ProjectTaskTracking from "../../blocks/ProjectTaskTracking/src/ProjectTaskTracking";
import ContentManagement from "../../blocks/ContentManagement/src/ContentManagement";
import CountryCodeSelector from "../../blocks/country-code-selector/src/CountryCodeSelector";
import ReceiptCustomisation from "../../blocks/ReceiptCustomisation/src/ReceiptCustomisation";
import LanguageSupport from "../../blocks/LanguageSupport/src/LanguageSupport";
import PhoneNumberInput from "../../blocks/mobile-account-registration/src/PhoneNumberInput";
import AdditionalDetailForm from "../../blocks/mobile-account-registration/src/AdditionalDetailForm";
import QuoteManagement from "../../blocks/QuoteManagement/src/QuoteManagement";
import SocialMediaAccountRegistrationScreen from "../../blocks/social-media-account-registration/src/SocialMediaAccountRegistrationScreen";
import AutomaticReminders from "../../blocks/AutomaticReminders/src/AutomaticReminders";
import Location from "../../blocks/location/src/Location";
import AddContactus from "../../blocks/contactus/src/AddContactus";
import EmailAccountRegistration from "../../blocks/email-account-registration/src/EmailAccountRegistration";
import FileAttachment from "../../blocks/FileAttachment/src/FileAttachment";
import EmailAccountLoginBlock from "../../blocks/email-account-login/src/EmailAccountLoginBlock";
import LandingPage from "../../blocks/landingpage/src/LandingPage";
import CustomisedOrderStatus from "../../blocks/CustomisedOrderStatus/src/CustomisedOrderStatus";
import TimesheetManagement from "../../blocks/TimesheetManagement/src/TimesheetManagement";
import EmailNotifications from "../../blocks/EmailNotifications/src/EmailNotifications";
import AdminConsole2 from "../../blocks/AdminConsole2/src/AdminConsole2";
import CfSaasRequirement22 from "../../blocks/CfSaasRequirement22/src/CfSaasRequirement22";
import Settings5 from "../../blocks/Settings5/src/Settings5";
import CfSaasRequirement12 from "../../blocks/CfSaasRequirement12/src/CfSaasRequirement12";
import TaskList from "../../blocks/tasks/src/TaskList";
import Task from "../../blocks/tasks/src/Task";
import SaveAsPdf from "../../blocks/saveaspdf/src/SaveAsPdf";
import ImportExportData from "../../blocks/importexportdata/src/ImportExportData";
import PaymentAdmin from "../../blocks/PaymentAdmin/src/PaymentAdmin";
import BulkUploading from "../../blocks/bulkuploading/src/BulkUploading";
import TermsConditions from "../../blocks/termsconditions/src/TermsConditions";
import TermsConditionsDetail from "../../blocks/termsconditions/src/TermsConditionsDetail";
import TermsConditionsUsers from "../../blocks/termsconditions/src/TermsConditionsUsers";
import AccountGroups from "../../blocks/accountgroups/src/AccountGroups";
import RolesPermissions from "../../blocks/RolesPermissions/src/RolesPermissions";
import Sorting from "../../blocks/sorting/src/Sorting";
import Catalogue from "../../blocks/catalogue/src/Catalogue";
import EmailNotifications2 from "../../blocks/EmailNotifications2/src/EmailNotifications2";
import Home from "../../blocks/landingpage/src/Home.web";
import WhyLamsa from "../../blocks/landingpage/src/WhyLamsa.web";
import Pricing from "../../blocks/landingpage/src/Pricing.web";
import ContactUs from "../../blocks/landingpage/src/ContactUs.web";
import TermsAndConditions from "../../blocks/landingpage/src/TermsAndConditions.web";
import PrivacyPolicy from "../../blocks/landingpage/src/PrivacyPolicy.web";
import Create from "../../blocks/dashboard/src/Create.web";
import Dashboard from "../../blocks/dashboard/src/Dashboard.web";
import Maps from "../../blocks/maps/src/Maps.web";
import Invoices from "../../blocks/InvoiceBilling/src/Invoices.web";
import Invoice from "../../blocks/InvoiceBilling/src/Invoice.web";
import Calendars from "../../blocks/appointmentmanagement/src/Calendar.web";
import Timesheet from "../../blocks/TimesheetManagement/src/Timesheet.web";
import SupplierTabs from "../../blocks/CustomisableUserProfiles/src/SupplierTabs.web";
import Suppliers from "../../blocks/CustomisableUserProfiles/src/Suppliers.web";
import Customers from "../../blocks/CustomisableUserProfiles/src/Customers.web";
import CustomerTabs from "../../blocks/CustomisableUserProfiles/src/CustomerTabs.web";
import Employees from "../../blocks/CustomisableUserProfiles/src/Employees.web";
import CreateEmployee from "../../blocks/CustomisableUserProfiles/src/CreateEmployee.web";
import EmployeeChangePassword from "../../blocks/dashboard/src/EmployeeChangePassword.web";
import EmployeeDashboard from "../../blocks/CustomisableUserProfiles/src/EmployeeDashboard.web";
import EmployeeLogin from "../../blocks/email-account-login/src/EmployeeLogin.web";
import EmployeeLogout from "../../blocks/dashboard/src/EmployeeLogout.web";
import EmployeeProfile from "../../blocks/dashboard/src/EmployeeProfile.web";
import Reports from "../../blocks/ProjectReporting/src/Reports.web";
import RequestReport from "../../blocks/ProjectReporting/src/RequestReport.web";
import JobReport from "../../blocks/ProjectReporting/src/JobReport.web";
import JobFinancialReport from "../../blocks/ProjectReporting/src/JobFinancialReport.web";
import TimesheetReport from "../../blocks/ProjectReporting/src/TimesheetReport.web";
import Settings from "../../blocks/Settings/src/Settings.web";
import CompanyInformation from "../../blocks/Settings/src/CompanyInformation.web";
import ProductAndServices from "../../blocks/Settings/src/ProductAndServices.web";
import Billing from "../../blocks/Settings/src/Billing.web";
import Notification from "../../blocks/Settings/src/Notification.web";
import AddPayment from "../../blocks/Settings/src/AddPayment.web";
import ManagePlan from "../../blocks/Settings/src/ManagePlan.web";
import Profile from "../../blocks/dashboard/src/Profile.web";
import ChangePassword from "../../blocks/dashboard/src/ChangePassword.web";
import Logout from "../../blocks/dashboard/src/Logout.web";
import Search from "../../blocks/advancedsearch/src/Search.web";
import FAQs from "../../blocks/dashboard/src/FAQs.web";
import FreeTrialExpired from "../../blocks/dashboard/src/FreeTrialExpired.web";
import ResetPassword from "../../blocks/email-account-login/src/ResetPassword.web";
import UserAccessWeb from "../../blocks/email-account-login/src/UserAccess.web"
import { LoadScript } from '@react-google-maps/api';
import Customisableuserprofiles2 from "../../blocks/customisableuserprofiles2/src/Customisableuserprofiles2";
import OrderManagement from "../../blocks/ordermanagement/src/OrderManagement";
import Customform3 from "../../blocks/customform3/src/Customform3";
import Contentmanagement3 from "../../blocks/contentmanagement3/src/Contentmanagement3";
import Notes2 from "../../blocks/notes2/src/Notes2";
import Settings2 from "../../blocks/settings2/src/Settings2";
import Rolesandpermissions from "../../blocks/rolesandpermissions/src/Rolesandpermissions";
import Joblisting from "../../blocks/joblisting/src/JobListing";
import Timesheetmanagement2 from "../../blocks/timesheetmanagement2/src/Timesheetmanagement2";
import Receiptcustomisation2 from "../../blocks/receiptcustomisation2/src/Receiptcustomisation2";
import Quotemanagement2 from "../../blocks/quotemanagement2/src/Quotemanagement2";
import Customform4 from "../../blocks/customform4/src/Customform4";
import Cfamazonpaymentservices from "../../blocks/cfamazonpaymentservices/src/Cfamazonpaymentservices";
import Rolesandpermissions2 from "../../blocks/rolesandpermissions2/src/Rolesandpermissions2";





import OtpPassword from "../../blocks/email-account-login/src/OtpPassword.web"
import PasswordForgot from "../../blocks/email-account-login/src/PasswordForgot.web"
import UserProfile from "../../blocks/email-account-registration/src/UserProfile.web"

// Request Routes
import Requests from "../../blocks/RequestManagement/src/Requests.web";
import RequestForm from "../../blocks/RequestManagement/src/RequestForm.web"
import RequestEdit from "../../blocks/RequestManagement/src/RequestEdit.web"
// jobs routes
import Jobs from "../../blocks/JobListing2/src/Jobs.web"
import CreateJob from "../../blocks/JobListing2/src/CreateJob.web"
import JobEdit from "../../blocks/JobListing2/src/JobEdit.web"
// Quotes Routes
import Quotes from "../../blocks/QuoteManagement/src/Quotes.web"
import QuoteCreate from "../../blocks/QuoteManagement/src/QuoteCreate.web"

import TermsAndCondition from "../../blocks/TermsAndConditions/src/TermsAndConditions.web.tsx"
import { getStorageData } from "../../framework/src/Utilities";
import TimeSheetHours from "../../blocks/TimesheetManagement/src/TimeSheetHours.web";
import TimeSheetWeekly from "../../blocks/TimesheetManagement/src/TimeSheetWeekly.web";

const routeMap = {
  TaskList: {
    component: TaskList,
    path: "/TaskList"
  },
  Task: {
    component: Task,
    path: "/Task"
  },
  SaveAsPdf: {
    component: SaveAsPdf,
    path: "/SaveAsPdf"
  },
  ImportExportData: {
    component: ImportExportData,
    path: "/ImportExportData"
  },
  PaymentAdmin: {
    component: PaymentAdmin,
    path: "/PaymentAdmin"
  },
  BulkUploading: {
    component: BulkUploading,
    path: "/BulkUploading"
  },
  TermsConditions: {
    component: TermsConditions,
    path: "/TermsConditions"
  },
  TermsConditionsDetail: {
    component: TermsConditionsDetail,
    path: "/TermsConditionsDetail"
  },
  TermsConditionsUsers: {
    component: TermsConditionsUsers,
    path: "/TermsConditionsUsers"
  },
  AccountGroups: {
    component: AccountGroups,
    path: "/AccountGroups"
  },
  RolesPermissions: {
    component: RolesPermissions,
    path: "/RolesPermissions"
  },
  Sorting: {
    component: Sorting,
    path: "/Sorting"
  },
  Catalogue: {
    component: Catalogue,
    path: "/Catalogue"
  },
  EmailNotifications2: {
    component: EmailNotifications2,
    path: "/EmailNotifications2"
  },
Cfamazonpaymentservices:{
 component:Cfamazonpaymentservices,
path:"/Cfamazonpaymentservices"},
Rolesandpermissions2:{
 component:Rolesandpermissions2,
path:"/Rolesandpermissions2"},

Receiptcustomisation2:{
 component:Receiptcustomisation2,
path:"/Receiptcustomisation2"},
Quotemanagement2:{
 component:Quotemanagement2,
path:"/Quotemanagement2"},
Customform4:{
 component:Customform4,
path:"/Customform4"},

Customisableuserprofiles2:{
 component:Customisableuserprofiles2,
path:"/Customisableuserprofiles2"},
OrderManagement:{
 component:OrderManagement,
path:"/OrderManagement"},
Customform3:{
 component:Customform3,
path:"/Customform3"},
Contentmanagement3:{
 component:Contentmanagement3,
path:"/Contentmanagement3"},
Notes2:{
 component:Notes2,
path:"/Notes2"},
Settings2:{
 component:Settings2,
path:"/Settings2"},
Rolesandpermissions:{
 component:Rolesandpermissions,
path:"/Rolesandpermissions"},
Joblisting:{
 component:Joblisting,
path:"/Joblisting"},
Timesheetmanagement2:{
 component:Timesheetmanagement2,
path:"/Timesheetmanagement2"},

// Tasks:{
//  component:Tasks,
// path:"/Tasks"},
Settings:{
 component:Settings,
path:"/Settings"},
CfSaasRequirement22:{
 component:CfSaasRequirement22,
path:"/CfSaasRequirement22"},
Maps:{
 component:Maps,
path:"/Maps"},
Settings5:{
 component:Settings5,
path:"/Settings5"},
CfSaasRequirement12:{
 component:CfSaasRequirement12,
path:"/CfSaasRequirement12"},
TermsAndConditions:{
 component:TermsAndConditions,
path:"/TermsAndConditions"},

  AdminConsole2: {
    component: AdminConsole2,
    path: "/AdminConsole2"
  },

  ProjectReporting: {
    component: ProjectReporting,
    path: "/ProjectReporting"
  },
  VisualAnalytics: {
    component: VisualAnalytics,
    path: "/VisualAnalytics"
  },
  CustomisableUserProfiles: {
    component: CustomisableUserProfiles,
    path: "/CustomisableUserProfiles"
  },
  TaskAllocator: {
    component: TaskAllocator,
    path: "/Tasks"
  },
  SocialMediaAccountLoginScreen: {
    component: SocialMediaAccountLoginScreen,
    path: "/SocialMediaAccountLoginScreen"
  },
  CreditdebitCardPayments: {
    component: CreditdebitCardPayments,
    path: "/CreditdebitCardPayments"
  },
  MultipleCurrencySupport: {
    component: MultipleCurrencySupport,
    path: "/MultipleCurrencySupport"
  },
  DataImportexportcsv: {
    component: DataImportexportcsv,
    path: "/DataImportexportcsv"
  },
  MobileAccountLoginBlock: {
    component: MobileAccountLoginBlock,
    path: "/MobileAccountLoginBlock"
  },
  TaxCalculator: {
    component: TaxCalculator,
    path: "/TaxCalculator"
  },
  ProjectTemplates: {
    component: ProjectTemplates,
    path: "/ProjectTemplates"
  },
  AdvancedSearch: {
    component: AdvancedSearch,
    path: "/AdvancedSearch"
  },
  OTPInputAuth: {
    component: OTPInputAuth,
    path: "/OTPInputAuth"
  },
  RolesPermissions2: {
    component: RolesPermissions2,
    path: "/RolesPermissions2"
  },
  InvoiceBilling: {
    component: InvoiceBilling,
    path: "/InvoiceBilling"
  },
  RequestManagement: {
    component: RequestManagement,
    path: "/RequestManagement"
  },
  PeopleManagement2: {
    component: PeopleManagement2,
    path: "/PeopleManagement2"
  },
  Notes: {
    component: Notes,
    path: "/Notes"
  },
  PaymentAdmin2: {
    component: PaymentAdmin2,
    path: "/PaymentAdmin2"
  },
  Customform: {
    component: Customform,
    path: "/Customform"
  },
  JobListing2: {
    component: JobListing2,
    path: "/JobListing2"
  },
  Pushnotifications: {
    component: Pushnotifications,
    path: "/Pushnotifications"
  },
  ForgotPassword: {
    component: ForgotPassword,
    path: "/ForgotPassword"
  },
  ForgotPasswordOTP: {
    component: ForgotPasswordOTP,
    path: "/ForgotPasswordOTP"
  },
  NewPassword: {
    component: NewPassword,
    path: "/NewPassword"
  },
  Notifications: {
    component: NotificationStyles,
    path: "/Notifications"
  },
  Analytics: {
    component: Analytics,
    path: "/Analytics"
  },
  Customisableusersubscriptions: {
    component: Customisableusersubscriptions,
    path: "/Customisableusersubscriptions"
  },
  SubscriptionDetails: {
    component: SubscriptionDetails,
    path: "/SubscriptionDetails"
  },
  AmazonPayIntegration: {
    component: AmazonPayIntegration,
    path: "/AmazonPayIntegration"
  },
  BaselineReporting: {
    component: BaselineReporting,
    path: "/BaselineReporting"
  },
  AdminConsole3: {
    component: AdminConsole3,
    path: "/AdminConsole3"
  },
  Appointments: {
    component: Appointments,
    path: "/Appointments"
  },
  AddAppointment: {
    component: AddAppointment,
    path: "/AddAppointment"
  },
  Scheduling: {
    component: Scheduling,
    path: "/Scheduling"
  },
  ProjectTaskTracking: {
    component: ProjectTaskTracking,
    path: "/ProjectTaskTracking"
  },
  ContentManagement: {
    component: ContentManagement,
    path: "/ContentManagement"
  },
  CountryCodeSelector: {
    component: CountryCodeSelector,
    path: "/CountryCodeSelector"
  },
  ReceiptCustomisation: {
    component: ReceiptCustomisation,
    path: "/ReceiptCustomisation"
  },
  LanguageSupport: {
    component: LanguageSupport,
    path: "/LanguageSupport"
  },
  PhoneNumberInput: {
    component: PhoneNumberInput,
    path: "/PhoneNumberInput"
  },
  AdditionalDetailForm: {
    component: AdditionalDetailForm,
    path: "/AdditionalDetailForm"
  },
  QuoteManagement: {
    component: QuoteManagement,
    path: "/QuoteManagement"
  },
  SocialMediaAccountRegistrationScreen: {
    component: SocialMediaAccountRegistrationScreen,
    path: "/SocialMediaAccountRegistrationScreen"
  },
  AutomaticReminders: {
    component: AutomaticReminders,
    path: "/AutomaticReminders"
  },
  Location: {
    component: Location,
    path: "/Location"
  },
  AddContactus: {
    component: AddContactus,
    path: "/AddContactus"
  },
  EmailAccountRegistration: {
    component: EmailAccountRegistration,
    path: "/EmailAccountRegistration"
  },
  FileAttachment: {
    component: FileAttachment,
    path: "/FileAttachment"
  },
  EmailAccountLoginBlock: {
    component: EmailAccountLoginBlock,
    path: "/EmailAccountLoginBlock"
  },
  LandingPage: {
    component: LandingPage,
    path: "/LandingPage"
  },
  CustomisedOrderStatus: {
    component: CustomisedOrderStatus,
    path: "/CustomisedOrderStatus"
  },
  TimesheetManagement: {
    component: TimesheetManagement,
    path: "/TimesheetManagement"
  },
  EmailNotifications: {
    component: EmailNotifications,
    path: "/EmailNotifications"
  },

  LanguageSupport: {
    component: Home,
    path: '/',
    exact: true
  },

  InfoPage: {
    component: InfoPage,
    path: '/InfoPage'
  },

  AlertWeb: {
    component: AlertBlock,
    path: "*/AlertWeb",
    modal: true
  },
  Home: {
    component: Home,
    path: "/Home"
  },
  WhyLamsa: {
    component: WhyLamsa,
    path: "/WhyLamsa"
  },
  Pricing: {
    component: Pricing,
    path: "/Pricing"
  },
  ContactUs: {
    component: ContactUs,
    path: "/ContactUs"
  },
  TermsAndConditions: {
    component: TermsAndConditions,
    path: "/TermsAndConditions"
  },
  PrivacyPolicy: {
    component: PrivacyPolicy,
    path: "/PrivacyPolicy"
  },
  Create: {
    component: Create,
    path: "/Create"
  },
  Dashboard: {
    component: Dashboard,
    path: "/Dashboard"
  },
  Requests: {
    component: Requests,
    path: "/Requests"
  },
  Maps: {
    component: Maps,
    path: "/Maps"
  },
  Invoices: {
    component: Invoices,
    path: "/Invoices"
  },
  Invoice: {
    component: Invoice,
    path: "/invoice"
  },
  Calendar: {
    component: Calendars,
    path: "/Calendar"
  },
  Timesheet: {
    component: Timesheet,
    path: "/Timesheet"
  },
  TimeSheetHours:{
    component: TimeSheetHours,
    path: "/TimeSheetHours"
  },
  TimeSheetWeekly:{
    component: TimeSheetWeekly,
    path: "/TimeSheetWeekly"
  },
  Suppliers: {
    component: Suppliers,
    path: "/Suppliers"
  },
  CreateSupplier: {
    component: SupplierTabs,
    path: "/CreateSupplier"
  },
  SupplierData: {
    component: SupplierTabs,
    path: "/SupplierData"
  },
  EditSupplier: {
    component: SupplierTabs,
    path: "/EditSupplier"
  },
  PurchaseOrders: {
    component: SupplierTabs,
    path: "/PurchaseOrders"
  },
  CreatePurchase: {
    component: SupplierTabs,
    path: "/CreatePurchase"
  },
  PurchaseData: {
    component: SupplierTabs,
    path: "/PurchaseData"
  },
  Customers: {
    component: Customers,
    path: "/Customers"
  },
  CreateCustomer: {
    component: CustomerTabs,
    path: "/CreateCustomer"
  },
  EditCustomer: {
    component: CustomerTabs,
    path: "/EditCustomer"
  },
  CustomerSites: {
    component: CustomerTabs,
    path: "/CustomerSites"
  },
  CustomerRequests: {
    component: CustomerTabs,
    path: "/CustomerRequests"
  },
  CustomerQuotes: {
    component: CustomerTabs,
    path: "/CustomerQuotes"
  },
  CustomerJobs: {
    component: CustomerTabs,
    path: "/CustomerJobs"
  },
  CustomerInvoices: {
    component: CustomerTabs,
    path: "/CustomerInvoices"
  },
  Employees: {
    component: Employees,
    path: "/Employees"
  },
  CreateEmployee: {
    component: CreateEmployee,
    path: "/CreateEmployee"
  },
  EmployeeProfile: {
    component: EmployeeProfile,
    path: "/Employee/Profile"
  },
  EmployeeChangePassword: {
    component: EmployeeChangePassword,
    path: "/Employee/ChangePassword"
  },
  EmployeeLogout: {
    component: EmployeeLogout,
    path: "/Employee/Logout"
  },
  EmployeeLogin: {
    component: EmployeeLogin,
    path: "/Employee/Login"
  },
  EmployeeDashboard: {
    component: EmployeeDashboard,
    path: "/Employee/Dashboard"
  },
  Reports: {
    component: RequestReport,
    path: "/Reports",
    exact: true
  },
  RequestReport: {
    component: RequestReport,
    path: "/Reports/Requests"
  },
  JobReport: {
    component: JobReport,
    path: "/Reports/JobReport"
  },
  JobFinancialReport: {
    component: JobFinancialReport,
    path: "/Reports/JobFinancialReport"
  },
  TimesheetReport: {
    component: TimesheetReport,
    path: "/Reports/Timesheet"
  },
  Settings: {
    component: CompanyInformation,
    path: "/Settings",
    exact: true
  },
  CompanyInformation: {
    component: CompanyInformation,
    path: "/Settings/CompanyInformation"
  },
  ProductAndServices: {
    component: ProductAndServices,
    path: "/Settings/ProductAndServices"
  },
  Billing: {
    component: Billing,
    path: "/Settings/Billing"
  },
  AddPayment: {
    component: AddPayment,
    path: "/Settings/AddPayment"
  },
  ManagePlan: {
    component: ManagePlan,
    path: "/Settings/ManagePlan"
  },
  Notification: {
    component: Notification,
    path: "/Settings/Notification"
  },
  Profile: {
    component: Profile,
    path: "/Profile"
  },
  ChangePassword: {
    component: ChangePassword,
    path: "/ChangePassword"
  },
  Logout: {
    component: Logout,
    path: "/Logout"
  },
  Search: {
    component: Search,
    path: "/Search"
  },
  FAQs: {
    component: FAQs,
    path: "/FAQs"
  },
  FreeTrialExpired: {
    component: FreeTrialExpired,
    path: "/FreeTrialExpired"
  },
  ResetPassword: {
    component: ResetPassword,
    path: "/ResetPassword"
  },
  PasswordForgot: {
    component: PasswordForgot,
    path: "/PasswordForgot"
  },
  UserAccessWeb: {
    component: UserAccessWeb,
    path: "/UserAccessWeb"
  },
  OtpPassword: {
    component: OtpPassword,
    path: "/OtpPassword"
  },
  UserProfile: {
    component: UserProfile,
    path: "/UserProfile"
  },
  Login: {
    component: UserAccessWeb,
    path: "/Login"
  },
  Register: {
    component: UserAccessWeb,
    path: "/Register"
  },
  // Request Routes
  RequestForm: {
    component: RequestForm,
    path: "/Request/create"
  },
  RequestEdit: {
    component: RequestEdit,
    path: "/Request/edit"
  },
  Quotes:{
    component:Quotes,
    path:"/Quotes"
  },
  Jobs: {
    component: Jobs,
    path: '/Jobs'
  },
  CreateJob: {
    component: CreateJob,
    path: "/Job/Create"
  },
  JobEdit: {
    component: JobEdit,
    path: "/Job/Edit"
  },
  Quotes:{
    component:Quotes,
    path:"/Quotes"
  },
  QuoteCreate:{
    component:QuoteCreate,
    path:"/Quote/Create"
  },
  TermsAndCondition:{
    component:TermsAndCondition,
    path:"/Terms"
  }
};

const firebaseAPI = firebase.initializeApp({
  apiKey: "AIzaSyDgl9aTbKMdRZ9-ijSZRionh3V591gMJl4",
  authDomain: "rnmasterapp-c11e9.firebaseapp.com",
  databaseURL: "https://rnmasterapp-c11e9.firebaseio.com",
  projectId: "rnmasterapp-c11e9",
  storageBucket: "rnmasterapp-c11e9.appspot.com",
  messagingSenderId: "649592030497",
  appId: "1:649592030497:web:7728bee3f2baef208daa60",
  measurementId: "G-FYBCF3Z2W3"
});

class App extends Component {
  async componentDidMount(){
    const languageSelected=await getStorageData("lang")
        if (languageSelected === 'ar') {
          window.document.querySelector("html").setAttribute("dir", "rtl");
        } else if (languageSelected ==="en") {
          window.document.querySelector("html").setAttribute("dir", "ltr");
        }
    };


  render() {

    const defaultAnalytics = firebaseAPI.analytics();
    defaultAnalytics.logEvent('APP_Loaded');

    return (
      <View style={{ height: '100vh' }}>
        {/* <TopNav /> */}
        <ModalContainer />
        <LoadScript
          googleMapsApiKey="AIzaSyD--KtNzcav1Kh1toELW4RHw-NqiFVpeH8"
          libraries={['places']}
          loadingElement={<span></span>}
        >
          {WebRoutesGenerator({ routeMap })}
        </LoadScript>
      </View>
    );
  }
}

export default App;